import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './index.css';
import Index from './pages/index/Index';
import Home from './pages/home/Home';

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<Index />}/>
        <Route path="home" element={<Home />}/>
      </Routes>
    </BrowserRouter>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App/>);