import './Home.css';
import React from 'react'
import { Header } from './components/Header'
import { LocationsContainer } from './components/LocationsContainer';

class Home extends React.Component {

  constructor(props) {
      super(props);
      this.state = {
          isLoading: true,
          userDetails: null,
      }
  }

  componentDidMount() {
    this.load();
    this.load = this.load.bind(this);
  }

  load() {
      this.setState({
        isLoading: true,
        userDetails: null,
      });
      fetch("/user/details")
          .then(res => {
              if(res.status == 200)
                  return res.json();
              if(res.status == 403) {
                  window.location.href = "/login";
                  throw Error("Unauthorized");
              }
              throw Error({message: "Server error: " + res.status, result: res});
          })
          .then(
              (result) => {
                  this.setState({
                    isLoading: false,
                    userDetails: result,
                  })
              },
              (error) => {
                  this.setState({
                    isLoading: false,
                    userDetails: null,
                  });
              }
          )
  }

  render() {
      const {isLoading, userDetails} = this.state;
      if(isLoading) {
        return (
            <>
            </>
        );
      }
      if(userDetails == null) {
        return (
            <>
            <div>Occured unknown error</div>
            </>
        );
      }

      return (
          <>
          <Header userDetails={userDetails} />
          <LocationsContainer userDetails={userDetails} />
          </>
      )
  }
}

export default Home;
