import LoggedUserWidget from '../../../components/LoggedUserWidget';
import UserDetails from '../../../components/UserDetails';
import './Header.css'
import React from "react";

export function Header(props) {
    return (
        <>
        <div className="header">
            <LoggedUserWidget userDetails={props.userDetails} logged={true}/>
            {/* <span>Hi {props.userDetails.name}</span>
            <a style={{marginLeft: '16px',}} href="logout">Logout</a> */}
        </div>
        </>
    )
}