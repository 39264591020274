const State = {
    IDLE: 0,
    LOADING: 1,
    ERROR: 2
}

export default class LoadingState {
    
    static Idle() {
        return new LoadingState(State.IDLE);
    }

    static Loading() {
        return new LoadingState(State.LOADING);
    }

    static Error() {
        return new LoadingState(State.Error);
    }

    static Error(error) {
        return new LoadingState(State.Error, error);
    }

    state;
    error;

    constructor(state = State.IDLE, error = null) {
        this.state = state;
        this.error = error;
    }

    isLoading() {
        return this.state == State.LOADING;
    }

    errorOccured() {
        return this.error != null;
    }
}